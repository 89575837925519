import React from "react";
import useDemo from "./useDemo";

const Demo = () => {
  const onSmallScreen = useDemo();

  return <div>{`${onSmallScreen}`}</div>;
};

export default Demo;
